.module.search-form {
    
    .search-input > form {
    
        transition: all 1s ease;
        
        display: block;
        margin: 0 auto;
    
        .input-group-text {
            background: $white;
        }
    
        .input-group > * {
            border: none;
        }
    
        img {
            filter: grayscale(40%) drop-shadow(0 0 5px $primary);
        }
    
        max-width: 576px;
        border-radius:  0 0 22px 22px;
        overflow: hidden;
    }
    
    .search-input > form.size-big {
        margin: 20px auto;
        border-radius: 22px 0 0 22px;
        max-width: 768px;
    
        img {
            filter: grayscale(100%);
        }
    }

}