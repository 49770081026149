body {
    background: #d7d7d7;
}

*:focus {
    outline: 0 !important;
}

.module {

}