.module.reddit-posts {
    
    padding: 20px 0;
    
    .spinner-border {

        display: block;
        margin: 20px auto;
        color: $primary;
    }

    .post-result {

        margin: 20px;
        background: $white;
        border-radius: 10px;
        overflow: hidden;


        .post-thumbnail {
            
            position: relative;
            
            .post-author {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: -10px;
                padding: 0 10px;
                background: rgba($color: $primary, $alpha: 1);
                color: $white;
                border-radius: 5px;                
            }

            img {
                display: block;
                margin: auto;
                object-fit: cover;
                width: 100%;
                height: 230px;
            }
        }

        .post-data {

            .post-title {
                margin: 15px 0;
                padding: 0px 10px;
                text-align: center;
                font-size: 18px;
                line-height: 20px;
                height: 40px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .post-content {
                text-overflow: ellipsis ellipsis;
                line-height: 20px;
                padding: 10px;
                overflow: hidden;
                white-space: nowrap;
            }
        }

    }
}