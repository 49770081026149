.module.search-header {
    
    .search-header-block {

        border-top: solid 2px $primary;

        padding: 80px 0;
        text-align: center;

        span {
            color: rgba($color: $primary, $alpha: 0.8);
        }

        .page-title {
            font-size: 42px;
            font-weight: bold;
        }

        .page-slogan {
            font-size: 22px;
        }

    }

    .search-header-block.hidden {
        display: none;
    }

}